import { useQuery } from "@vue/apollo-composable";
import { PortfolioPropertiesDocument } from "~/graphql/generated/graphql";
export var usePortfolioPropertiesQuery = function usePortfolioPropertiesQuery(variables) {
  var query = useQuery(PortfolioPropertiesDocument, variables);
  var loading = computed(function () {
    return query.loading.value;
  });
  var result = computed(function () {
    var _query$result$value$a, _query$result$value;
    return (_query$result$value$a = (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementProperties.items) !== null && _query$result$value$a !== void 0 ? _query$result$value$a : [];
  });
  var metadata = computed(function () {
    var _query$result$value2;
    return (_query$result$value2 = query.result.value) === null || _query$result$value2 === void 0 ? void 0 : _query$result$value2.assetManagementProperties.metadata;
  });
  return {
    loading: loading,
    result: result,
    metadata: metadata
  };
};